import React, { Fragment, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddQuickFilterBody from './AddQuickFilterBody';
import { Button, Modal } from '../../../common';
import { addQuickLink } from '../../../api/quick-links-api';
import { useDispatch } from 'react-redux';
import { getQuickLinks } from '../../../reducers/quick-links-slice';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { findQuickLink } from '../../../utils/filters-util';

const AddQuickFilter = ({ sourceClass = '', quickLinks = [] }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const formRef = useRef(null);

  const handleSave = async (values) => {
    if (!values.name) return;

    const payload = {
      name: values.name,
      body: `${location.pathname}${location.search}`,
      source_class: sourceClass,
    };

    try {
      setLoading(true);
      const res = await addQuickLink(payload);
      if (res && res?.status) {
        dispatch(getQuickLinks(sourceClass));
        setSaveModal(false);
      }
    } catch (err) {
      toast.error(getErrorString(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        icon={'BsPlus'}
        size="medium"
        shape="round"
        onClick={() => setSaveModal(true)}
        disabled={!location.search || findQuickLink({ location: location, quickLinks: quickLinks })}
      >
        {'Add Quick Filter'}
      </Button>
      <Modal
        title={'Add Quick Filter'}
        open={saveModal}
        okButtonProps={{ loading }}
        okText="Save"
        ModalBody={<AddQuickFilterBody handleSave={handleSave} ref={formRef} />}
        handleOk={() => {
          formRef?.current?.submitForm();
        }}
        handleCancel={() => {
          setSaveModal(false);
        }}
        width={525}
        destroyOnClose={true}
        showActions={true}
      />
    </Fragment>
  );
};

export default AddQuickFilter;
