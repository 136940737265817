export const IconImg = (props) => (
  <svg
    fill={props.color || 'currentColor'}
    viewBox="0 0 16 16"
    width={props.size || '1em'}
    height={props.size || '1em'}
    {...props}
  >
    <path d="M13.583.5H2.417A1.92 1.92 0 0 0 .5 2.417v11.166A1.92 1.92 0 0 0 2.417 15.5h11.166a1.92 1.92 0 0 0 1.917-1.917V2.417A1.92 1.92 0 0 0 13.583.5zm1.034 13.083c0 .57-.464 1.034-1.034 1.034H2.417c-.57 0-1.033-.464-1.033-1.034v-1.611L4.29 9.498a.286.286 0 0 1 .368-.003l1.822 1.513a.442.442 0 0 0 .595-.028l4.328-4.334a.283.283 0 0 1 .217-.084c.047.002.137.02.207.105l2.789 3.434v3.482zm0-4.884L12.514 6.11a1.171 1.171 0 0 0-1.736-.089l-4.043 4.05-1.511-1.255a1.167 1.167 0 0 0-1.505.009l-2.335 1.987V2.417c0-.57.463-1.033 1.033-1.033h11.166c.57 0 1.034.463 1.034 1.033v6.282z" />
    <path d="M4.222 2.344c-1.175 0-2.13.955-2.13 2.13 0 1.175.955 2.13 2.13 2.13 1.175 0 2.13-.955 2.13-2.13 0-1.175-.955-2.13-2.13-2.13zm0 3.377c-.687 0-1.247-.56-1.247-1.247s.56-1.247 1.247-1.247c.688 0 1.247.56 1.247 1.247s-.56 1.247-1.247 1.247z" />
  </svg>
);

export const DashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 16}
    height={props.height || 16}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 8h6.75M8 1.25v13.5M4.85 1.25h6.3c1.26 0 1.89 0 2.371.245.424.216.768.56.984.984.245.48.245 1.11.245 2.371v6.3c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.984.984-.48.245-1.11.245-2.371.245h-6.3c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984c-.245-.48-.245-1.11-.245-2.371v-6.3c0-1.26 0-1.89.245-2.371a2.25 2.25 0 0 1 .984-.984c.48-.245 1.11-.245 2.371-.245Z"
    />
  </svg>
);

export const LeadsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 18}
    height={props.height || 15}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 1.6a3.375 3.375 0 0 1 0 6.05m1.5 3.925c1.134.513 2.154 1.349 3 2.425m-15 0c1.46-1.858 3.442-3 5.625-3s4.165 1.142 5.625 3M10.5 4.625a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Z"
    />
  </svg>
);

export const PatientIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 18}
    height={props.height || 18}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.987 14.579A3 3 0 0 1 6.75 12.75h4.5a3 3 0 0 1 2.763 1.829M12 7.125a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
    />
  </svg>
);

export const OrdersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 12}
    height={props.height || 18}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.5 12a3 3 0 0 0 3 3h3a3 3 0 1 0 0-6h-3a3 3 0 0 1 0-6h3a3 3 0 0 1 3 3M6 1.5v15"
    />
  </svg>
);

export const CasesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 16}
    height={props.height || 18}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.5 8.25H4m1.5 3H4m6-6H4m9 2.625V5.1c0-1.26 0-1.89-.245-2.371a2.25 2.25 0 0 0-.984-.984C11.291 1.5 10.662 1.5 9.4 1.5H4.6c-1.26 0-1.89 0-2.371.245a2.25 2.25 0 0 0-.984.984C1 3.209 1 3.839 1 5.1v7.8c0 1.26 0 1.89.245 2.371.216.424.56.768.984.984.48.245 1.11.245 2.371.245h2.025m7.875 0-1.125-1.125m.75-1.875a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
    />
  </svg>
);

export const StatusesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 16}
    height={props.height || 12}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.75 6h-9m9-4.5h-9m9 9h-9m-3-4.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm0-4.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm0 9a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
    />
  </svg>
);

export const ProductsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 16}
    height={props.height || 16}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.25 4.85c0-1.26 0-1.89.245-2.371a2.25 2.25 0 0 1 .984-.984c.48-.245 1.11-.245 2.371-.245h6.3c1.26 0 1.89 0 2.371.245.424.216.768.56.984.984.245.48.245 1.11.245 2.371v6.3c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.984.984-.48.245-1.11.245-2.371.245h-6.3c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984c-.245-.48-.245-1.11-.245-2.371v-6.3Z"
    />
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.375 4.475c0-.21 0-.315-.04-.395a.375.375 0 0 0-.165-.164c-.08-.041-.185-.041-.395-.041h-1.55c-.21 0-.315 0-.395.04a.375.375 0 0 0-.164.165c-.041.08-.041.185-.041.395v1.55c0 .21 0 .315-.04.395a.375.375 0 0 1-.165.164c-.08.041-.185.041-.395.041h-1.55c-.21 0-.315 0-.395.04a.375.375 0 0 0-.164.165c-.041.08-.041.185-.041.395v1.55c0 .21 0 .315.04.395.037.07.094.128.165.164.08.041.185.041.395.041h1.55c.21 0 .315 0 .395.04.07.037.128.094.164.165.041.08.041.185.041.395v1.55c0 .21 0 .315.04.395.037.07.094.128.165.164.08.041.185.041.395.041h1.55c.21 0 .315 0 .395-.04a.375.375 0 0 0 .164-.165c.041-.08.041-.185.041-.395v-1.55c0-.21 0-.315.04-.395a.375.375 0 0 1 .165-.164c.08-.041.185-.041.395-.041h1.55c.21 0 .315 0 .395-.04a.375.375 0 0 0 .164-.165c.041-.08.041-.185.041-.395v-1.55c0-.21 0-.315-.04-.395a.375.375 0 0 0-.165-.164c-.08-.041-.185-.041-.395-.041h-1.55c-.21 0-.315 0-.395-.04a.375.375 0 0 1-.164-.165c-.041-.08-.041-.185-.041-.395v-1.55Z"
    />
  </svg>
);

export const SubscriptionsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 18}
    height={props.height || 12}
    fill={props.color || 'none'}
    {...props}
  >
    <path
      stroke={props.stroke || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.5 4.5h-15m6.75 3H4.5m-3-4.35v5.7c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164h10.2c.84 0 1.26 0 1.581-.164a1.5 1.5 0 0 0 .656-.655c.163-.32.163-.74.163-1.581v-5.7c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C15.361.75 14.941.75 14.1.75H3.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581Z"
    />
  </svg>
);

export const ArrowDownIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
    <path
      stroke="#64748B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7 2.917v8.166M11.083 7 7 11.083 2.917 7"
    />
  </svg>
);

export const ReSyncIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke={'var(--icon-color)' || '#334155'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.41 13.826a6.375 6.375 0 0 0 8.111-9.013l-.187-.325m-10.855 6.7a6.375 6.375 0 0 1 8.111-9.013M.87 11.25l2.05.55.548-2.05m9.064-3.5.55-2.05 2.048.55"
    />
  </svg>
);

export const MergeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.75 12a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z"
    />
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.25 16.5a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z"
    />
  </svg>
);

export const PersonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 18}
    height={props?.height || 17}
    fill="none"
    {...props}
  >
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.5 15.667c1.946-2.065 4.59-3.334 7.5-3.334s5.553 1.27 7.5 3.334M12.75 5.25a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
    />
  </svg>
);

export const DetailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 18}
    height={props?.height || 18}
    fill="none"
    {...props}
  >
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.583 8.167H9.417m4.166 3.333H9.417m4.166-6.667H9.417M6.5 1.5v15m-1-15h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.092 1.093C16.5 3.4 16.5 4.1 16.5 5.5v7c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092c-.535.273-1.235.273-2.635.273h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C1.5 14.6 1.5 13.9 1.5 12.5v-7c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C3.4 1.5 4.1 1.5 5.5 1.5Z"
    />
  </svg>
);

export const ActivityIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 16} height={props.height || 20} fill="none" {...props}>
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.833 1.667-7.422 8.906c-.29.349-.436.523-.438.67a.417.417 0 0 0 .155.331c.114.093.341.093.795.093H8l-.833 6.666 7.422-8.906c.29-.349.436-.523.438-.67a.417.417 0 0 0-.155-.331c-.114-.093-.341-.093-.795-.093H8l.833-6.666Z"
    />
  </svg>
);

export const ChatIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 18} height={props.height || 18} fill="none" {...props}>
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.833 6.083H9M4.833 9H11.5m-6.667 5v1.946c0 .444 0 .666.091.78.08.1.2.157.326.157.146 0 .32-.139.666-.416l1.988-1.59c.406-.325.61-.488.836-.603a2.5 2.5 0 0 1 .634-.223c.25-.051.51-.051 1.03-.051H12.5c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092C16.5 12.1 16.5 11.4 16.5 10V5.5c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.093C14.6 1.5 13.9 1.5 12.5 1.5h-7c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093C1.5 3.4 1.5 4.1 1.5 5.5v5.167c0 .775 0 1.162.085 1.48a2.5 2.5 0 0 0 1.768 1.768c.318.085.705.085 1.48.085Z"
    />
  </svg>
);

export const SupportIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 18} height={props.height || 18} fill="none" {...props}>
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.5 14V9a7.5 7.5 0 0 0-15 0v5m2.083 2.5A2.083 2.083 0 0 1 1.5 14.417V12.75a2.083 2.083 0 1 1 4.167 0v1.667c0 1.15-.933 2.083-2.084 2.083Zm10.834 0a2.083 2.083 0 0 1-2.084-2.083V12.75a2.083 2.083 0 0 1 4.167 0v1.667c0 1.15-.933 2.083-2.083 2.083Z"
    />
  </svg>
);

export const SendArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 16}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.875 8H2.75m-.063.219-1.751 5.23c-.138.412-.207.617-.157.744.043.11.135.193.248.225.131.036.329-.053.724-.23l12.533-5.64c.386-.174.579-.261.639-.382a.376.376 0 0 0 0-.332c-.06-.12-.253-.207-.639-.381L1.746 1.81c-.394-.177-.59-.266-.721-.23a.375.375 0 0 0-.249.225c-.05.126.019.331.155.741l1.756 5.292c.024.07.035.106.04.142a.375.375 0 0 1 0 .096.792.792 0 0 1-.04.142Z"
    />
  </svg>
);
