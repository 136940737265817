import React from 'react';
import ReactAvatar from 'react-avatar';
import { Avatar as AntdAvatar } from 'antd';
import { modifyHexWithLetter } from '../../utils';

const Avatar = ({ name = '', src = '', size = 30, customAvatarStyle = {}, ...rest }) => {
  const isImage = src && src.length > 0;
  const letter = name ? name?.charAt(0)?.toUpperCase() : 'A';

  if (isImage)
    return (
      <AntdAvatar
        size={size}
        style={{
          '--ant-color-text-placeholder': isImage ? 'transparent' : '#568c94',
          fontWeight: 700,
        }}
        src={isImage ? src : undefined}
        {...rest}
      >
        {!isImage ? letter : null}
      </AntdAvatar>
    );

  return (
    <ReactAvatar
      name={name?.trim()[0]}
      col
      size={size}
      textSizeRatio={2}
      round
      style={{ fontFamily: "'Work Sans', sans-serif", ...customAvatarStyle }}
      {...rest}
    />
  );
};

export default Avatar;
