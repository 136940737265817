import React, { Fragment, useState } from 'react';
import { Button, Modal } from '../../../common';
import ManageQuickFilteBody from './ManageQuickFilteBody';

const ManageQuickFilters = ({ sourceClass = '', quickLinks = [] }) => {
  const [saveModal, setSaveModal] = useState(false);

  return (
    <Fragment>
      <Button size="medium" onClick={() => setSaveModal(true)}>
        {'Manage'}
      </Button>
      <Modal
        title={'Manage Quick Filters'}
        open={saveModal}
        ModalBody={<ManageQuickFilteBody quickLinks={quickLinks} sourceClass={sourceClass} />}
        handleCancel={() => {
          setSaveModal(false);
        }}
        destroyOnClose={true}
        width={525}
        showActions={false}
      />
    </Fragment>
  );
};

export default ManageQuickFilters;
