import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Input, Select } from '../../../common';
import { formatOptionsForSelect, formatValueForSelect } from '../../../utils/filters-util';
import { Flex } from 'antd';

const CreateTicketModalBody = forwardRef(({ assignees = [], handleSubmit = () => {} }, ref) => {
  const formikRef = useRef();
  const getValidationSchema = () =>
    yup.object().shape({
      assignee: yup.object().nullable().required('Assignee is required'),
      description: yup.string().required('Description is required.'),
    });

  const getInitialValues = () => ({
    assignee: null,
    description: '',
  });

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef?.current?.submitForm();
    },
  }));

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={getValidationSchema()}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const { values, handleSubmit, handleChange, setFieldValue, handleBlur, touched, errors } = formikProps;
        return (
          <form>
            <Flex vertical gap={20}>
              <Select
                label={'Assignee'}
                placeholder={'Select Assigne'}
                value={formatValueForSelect({
                  obj: values.assignee,
                  key: 'name',
                })}
                options={formatOptionsForSelect({ list: assignees })}
                onChange={(option) => {
                  setFieldValue('assignee', option);
                }}
                onBlur={handleBlur('assignee')}
                errorMsg={errors?.assignee && touched?.assignee && errors?.assignee}
                required
              />
              <Input
                placeholder="Enter Description"
                name="description"
                label="Description"
                value={values?.description}
                errorMsg={errors?.description && touched?.description && errors?.description}
                onChange={handleChange}
                onPressEnter={handleSubmit}
                rows={2}
              />
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
});

export default CreateTicketModalBody;
