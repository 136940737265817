import { Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './LeadRecentActivities.module.scss';
import RecentActivity from '../../recent_activity/RecentActivity';
import { fetchLeadActivitiesApi } from '../../../api/activities-api';
import { SOURCE_CLASS_NAMES } from '../../../constants/generic-constants';
import toast from 'react-hot-toast';
import { getErrorString, isEmptyArray } from '../../../utils';
import { Spinner } from '../../../common';

const LeadActivities = ({ leadId = '' }) => {
  const [activites, setActivites] = useState([]);
  const [loading, setLoading] = useState(true);

  const filterActivitesData = (data = []) => {
    if (isEmptyArray(data)) return [];

    return data?.map((row) => {
      return {
        trackableType: row?.trackable_type,
        action: row?.action,
        timestamp: row?.created_at,
        messages: row?.messages ?? [],
      };
    });
  };

  const fetchActivities = async () => {
    if (!leadId) return;

    setLoading(true);
    try {
      const res = await fetchLeadActivitiesApi(SOURCE_CLASS_NAMES.LEAD, leadId);
      if (res && res?.status) {
        setActivites(filterActivitesData(res?.data));
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [leadId]);

  return (
    <Flex vertical gap={25} className={styles.activitiesContainer}>
      <div className="heading-4">{'Recent Activities'}</div>
      {loading ? <Spinner size="small" color="var(--ant-color-primary)" /> : <RecentActivity data={activites} />}
    </Flex>
  );
};

export default LeadActivities;
