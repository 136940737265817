import React, { Fragment, useState } from 'react';
import { Badge, Button, Sidebar } from '../../../common';
import { Flex } from 'antd';
import LeadPrescriptionsBody from './LeadPrescriptionsBody';

const LeadPrescription = ({ prescriptionData = [], prescriptionDescription = '' }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const prescriptionCount = prescriptionData?.length ?? 0;

  const getPrescriptionTitle = () => (
    <Flex align="center" gap={10}>
      <span>{'Prescriptions'}</span>
      <Badge count={prescriptionCount} />
    </Flex>
  );

  return (
    <Fragment>
      <Sidebar
        sidebarOpen={sideBarOpen}
        setSidebarOpen={setSideBarOpen}
        heading={getPrescriptionTitle()}
        description={prescriptionDescription}
        content={<LeadPrescriptionsBody prescriptionData={prescriptionData} />}
      />

      <Button size={'large'} onClick={() => setSideBarOpen((prevState) => !prevState)}>
        {'View prescriptions'}
        <Badge count={prescriptionCount} />
      </Button>
    </Fragment>
  );
};

export default LeadPrescription;
