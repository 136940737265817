import React from 'react';
import cx from 'clsx';
import { Flex } from 'antd';

const DetailCardRowItem = ({ label = '', value = '', isPrimaryText = false }) => {
  return (
    <Flex vertical gap={15}>
      <span className="text-2">{label}</span>
      <span className={cx({ ['primary-text']: isPrimaryText, ['subtitle-2']: true })}>{value}</span>
    </Flex>
  );
};

const DetailCardVertical = ({ cardTitle = '', cardData = [] }) => {
  return (
    <Flex vertical gap={20}>
      <div className="heading-4">{cardTitle}</div>
      {cardData?.length ? (
        cardData?.map((card, index) => <DetailCardRowItem key={card?.label + index} {...card} />)
      ) : (
        <div>No Data Found</div>
      )}
    </Flex>
  );
};

export default DetailCardVertical;
