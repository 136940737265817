import React from 'react';
import DetailCard from '../../detail-card/DetailCard';
import DisplayDateTime from '../../../utils/time/display_time';
import { Flex } from 'antd';
import StatusButton from '../../../utils/status/status_button';
import LeadPrescription from '../lead-prescriptions/LeadPrescription';

const LeadCases = ({ casesData = [] }) => {
  const getCaseDetailData = (caseItem = {}) => {
    const rowData = [
      {
        label: 'Clinician',
        value: caseItem?.clinician?.full_name ?? '-',
      },
      {
        label: 'Created at',
        value: DisplayDateTime(caseItem?.mdi_created_at) ?? '-',
      },
    ];
    return rowData;
  };

  const getCardTitle = (caseItem = {}) => {
    return `MDI Case ID ${caseItem?.mdi_case_id}`;
  };

  const getTitleExtraItems = (caseItem = {}) => {
    return (
      <Flex gap={10} style={{ overflowX: 'auto' }}>
        <StatusButton status={caseItem?.status} />
        {caseItem?.tag_names?.length > 0 &&
          caseItem.tag_names.map((tagName, index) => (
            <StatusButton key={tagName + index} status={{ name: tagName, bg_color: '#F2F5F7', color: '#334155' }} />
          ))}
      </Flex>
    );
  };

  const getPerscriptionDescription = (caseItem) => {
    return (
      <div className="text-ellipsis" style={{ '--ellipsis-max-ch': '18ch' }}>
        {getCardTitle(caseItem)}
      </div>
    );
  };

  return (
    <Flex vertical gap={15}>
      {casesData?.length ? (
        casesData?.map((caseItem) => (
          <DetailCard
            key={caseItem?.id}
            textToCopy={caseItem?.mdi_case_id}
            cardRowData={getCaseDetailData(caseItem)}
            cardTitle={getCardTitle(caseItem)}
            CardTitleExtraContent={getTitleExtraItems(caseItem)}
            CardActionContent={
              <LeadPrescription
                prescriptionDescription={getPerscriptionDescription(caseItem)}
                prescriptionData={caseItem?.prescriptions}
              />
            }
          />
        ))
      ) : (
        <div>No Data Found</div>
      )}
    </Flex>
  );
};

export default LeadCases;
