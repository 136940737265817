import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { addQuickLink, deleteQuickLink, fetchQuickLinks } from '../../api/quick-links-api';

const initialState = {
  data: [],
  pagination: {},
  loading: false,
};

export const getQuickLinks = createAsyncThunk('fetch/quick_links', async (sourceClass = '') => {
  try {
    const res = await fetchQuickLinks(sourceClass);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

export const saveQuickLinks = createAsyncThunk('create/quick_links', async (payload = {}, sourceClass = '') => {
  try {
    const res = await addQuickLink(payload);
    if (res && res?.status) {
      const updatedRes = await fetchQuickLinks(sourceClass);
      return { ...updatedRes, isSuccess: true };
    }
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const quickLinksSlice = createSlice({
  name: 'quick-links',
  initialState,
  reducers: {
    setQuickLinks: (state, action) => {
      if (action?.payload) {
        state.data = action.payload;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getQuickLinks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuickLinks.fulfilled, (state, action) => {
        state.data = action?.payload?.data ?? [];
        state.pagination = action?.payload?.meta?.pagination ?? {};
        state.loading = false;
      })
      .addCase(getQuickLinks.rejected, (state) => {
        state.data = [];
        state.pagination = {};
        state.loading = false;
      })
      .addCase(saveQuickLinks.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveQuickLinks.fulfilled, (state, action) => {
        if (action?.payload?.isSuccess) {
          state.quickLinks = action?.payload?.data ?? [];
        }
        state.loading = false;
      })
      .addCase(saveQuickLinks.rejected, (state) => {
        state.loading = false;
      });
    // .addCase(deleteQuickLinks.fulfilled, (state, action) => {
    //   state.quickLinks = state.quickLinks.filter((link) => link.id !== action.meta.arg);
    // });
  },
});

export default quickLinksSlice.reducer;
