import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { handleFilterChips } from '../../utils/filter-chips-util';

const useFilterChips = ({ dependencies = [], filtersList = [] }) => {
  const location = useLocation();
  const [filterChips, setFilterChips] = useState([]);

  useEffect(() => {
    setFilterChips(handleFilterChips({ filtersList: filtersList, location: location }));
  }, [location, ...dependencies]);

  return {
    filterChips,
  };
};

export default useFilterChips;
