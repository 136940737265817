import React, { Fragment } from 'react';
import { Button } from '../../../common';
import StatusButton from '../../../utils/status/status_button';
import { Select } from '../../../common';
import { Flex } from 'antd';
import { formatOptionsForSelect } from '../../../utils/filters-util';
import MergeLeadModal from '../../modals/merge-lead-modal/MergeLeadModal';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import { LEADS_PATH } from '../../../constants/admin-paths';

const LeadDetailHeader = ({
  leadDetail = {},
  leadStatuses = [],
  handleStatusChange = () => {},
  handleResyncData = () => {},
}) => {
  const patientFullName = leadDetail?.patient?.first_name + ' ' + leadDetail?.patient?.last_name;

  return (
    <Fragment>
      <div className="row mx-2">
        <Flex vertical gap={24}>
          <Flex justify="space-between" align="center">
            <Breadcrumb items={[{ title: 'Leads', path: LEADS_PATH }, { title: 'Lead Details' }]} />
            <Flex gap={10}>
              <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.UPDATE}>
                <Select
                  placeholder="Change Lead Status"
                  allowClear
                  onChange={handleStatusChange}
                  options={formatOptionsForSelect({ list: leadStatuses })}
                  selectSize="large"
                />
              </Permission>
              <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.MERGE_LEAD}>
                <MergeLeadModal leadId={leadDetail?.id || ''} buttonSize="large" />
              </Permission>
              <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.SYNC_LEAD}>
                <Button icon={'ReSyncIcon'} iconColor={'var(--gray-700)'} size="large" onClick={handleResyncData} />
              </Permission>
            </Flex>
          </Flex>
          <Flex align="center" gap={15}>
            <div className="heading-1">{patientFullName}</div>
            <StatusButton status={leadDetail?.status} />
          </Flex>
        </Flex>
      </div>
    </Fragment>
  );
};

export default LeadDetailHeader;
