import React, { Fragment, useState } from 'react';
import { Badge, Button, Sidebar } from '../../../common';
import { Col, Flex, Row } from 'antd';
import styles from './LeadOrders.module.scss';
import StatusButton from '../../../utils/status/status_button';
import DisplayDateTime from '../../../utils/time/display_time';

const ChargeCard = ({ charge = {} }) => {
  return (
    <Flex vertical className={styles.chargesCardContainer} gap={20}>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="subtitle-1">{`ID# ${charge.id}`}</div>
        </Col>
        <Col span={12}>
          <StatusButton status={charge?.status} />
        </Col>
      </Row>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-3">{'Total'}</div>
          <div className="subtitle-2">{`$${charge?.total ?? 0}`}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-3">{'Charged At'}</div>
          <div className="subtitle-2">{DisplayDateTime(charge?.charge_at) ?? '-'}</div>
        </Col>
      </Row>
    </Flex>
  );
};

const ChargesContent = ({ chargesData = [] }) => (
  <Flex vertical className={styles.chargesBodyContainer} style={{ minHeight: '100%' }} gap={24}>
    {chargesData?.length > 0
      ? chargesData?.map((charge) => <ChargeCard key={charge?.id} charge={charge} />)
      : 'No Prescription Found'}
  </Flex>
);

const LeadOrderCharges = ({ orderCharges = [], orderChargesDescription = '' }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const chargesCount = orderCharges?.length;

  const getChargesTitle = () => (
    <Flex align="center" gap={10}>
      <span>{'Charges'}</span>
      <Badge count={chargesCount} />
    </Flex>
  );

  return (
    <Fragment>
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        heading={getChargesTitle()}
        description={orderChargesDescription}
        content={<ChargesContent chargesData={orderCharges} />}
      />

      <Button size={'large'} onClick={() => setSidebarOpen((prevState) => !prevState)}>
        {'View Charges'}
        <Badge count={chargesCount} />
      </Button>
    </Fragment>
  );
};

export default LeadOrderCharges;
