import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { logout } from './logout-action';
import leadsSlice from './leads-slice';
import permissionsSlice from './permissions-slice';
import userSlice from './user-slice';
import quickLinksSlice from './quick-links-slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['permissionsData', 'userData'],
};

const reducers = combineReducers({
  permissionsData: permissionsSlice,
  userData: userSlice,
  leadsData: leadsSlice,
  quickLinksData: quickLinksSlice,
});

const modifiedReducers = (state, action) => {
  if (logout.match(action)) {
    return reducers(undefined, action); //clears redux state on logout
  }
  return reducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, modifiedReducers);
