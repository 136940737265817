import APIController from '../core-api-controller';

export const fetchQuickLinks = (sourceClass = '') => {
  const path = `${APIController.endpointApi}/user_filters?q[source_class_eq]=${sourceClass}`;
  return APIController.getApiCallback(path);
};

export const addQuickLink = (data = {}) => {
  const path = `${APIController.endpointApi}/user_filters`;
  return APIController.postCallback(path, data);
};

export const deleteQuickLink = (linkId = '') => {
  const path = `${APIController.endpointApi}/user_filters/${linkId}`;
  return APIController.deleteCallback(path);
};
