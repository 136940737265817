import { Flex } from 'antd';
import React from 'react';
import { showResetButton } from '../../../utils/filters-util';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterChip from './FilterChip';
import { Button } from '../../../common';

const SelectedFilters = ({ filterChips = [], filtersList = [], rootPathUrl = '' }) => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!filterChips?.length) return null;

  return (
    <Flex align="center" gap={12} className="flexGrow">
      <Flex align="center" gap={4}>
        <span
          style={{
            color: 'var(--gray-500)',
            fontSize: 'var(--ant-font-size-md)',
          }}
        >
          {'Selected Filters'}:
        </span>
      </Flex>
      <Flex wrap="wrap" gap={8}>
        {filterChips?.map((chip) => {
          return (
            <FilterChip
              baseList={filtersList}
              searchKey={chip.searchKey}
              searchValue={chip.searchValue}
              chipTitle={chip.title}
              key={`${chip.searchKey}=${chip.searchValue}`}
            />
          );
        })}

        {showResetButton(location) ? (
          <Button
            type="primary"
            shape="round"
            size="small"
            onClick={() => {
              navigate(rootPathUrl);
            }}
            style={{ '--ant-button-padding-inline-sm': '8px' }}
          >
            {'Clear All'}
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default SelectedFilters;
