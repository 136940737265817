import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Input } from '../../../common';
import { Flex } from 'antd';
import { getErrorString } from '../../../utils';
import { deleteQuickLink } from '../../../api/quick-links-api';
import { getQuickLinks } from '../../../reducers/quick-links-slice';
import toast from 'react-hot-toast';

const QuickFilterRow = ({ currentLink = {}, editEnabled = false, sourceClass = '' }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(currentLink.name);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDeleteLink = async () => {
    if (!currentLink?.id) return;
    try {
      setLoading(true);
      const res = await deleteQuickLink(currentLink.id);
      if (res && res?.status) {
        dispatch(getQuickLinks(sourceClass));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      styles={{
        body: {
          '--ant-padding-lg': 'var(--ant-padding-sm)',
        },
      }}
      bordered
    >
      <Flex align="center" justify="space-between" gap={12}>
        {editEnabled && editMode ? (
          <Input
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            containerClassName={'flexGrow'}
          />
        ) : (
          currentLink.name
        )}
        <Button icon={'IoTrashSharp'} iconSize={16} type="link" loading={loading} onClick={onDeleteLink} />
      </Flex>
    </Card>
  );
};

const ManageQuickFilteBody = ({ quickLinks = [], sourceClass = '' }) => {
  if (!quickLinks?.length) return <div className="d-flex justify-content-center">{'No Filter Found!'}</div>;
  return (
    <Flex vertical gap={10}>
      {quickLinks.map((quickLink) => (
        <QuickFilterRow currentLink={quickLink} sourceClass={sourceClass} />
      ))}
    </Flex>
  );
};

export default ManageQuickFilteBody;
