import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ size = 'large', color = 'gray', fontSize = 100 }) => {
  return (
    <Flex align="center" justify="center">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize,
              color,
            }}
            spin
          />
        }
        size={size}
      />
    </Flex>
  );
};
export default Spinner;
