import { Formik } from 'formik';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import * as yup from 'yup';
import { Input } from '../../../common';

const AddQuickFilterBody = forwardRef(({ handleSave = () => {} }, ref) => {
  const formikRef = useRef(null);
  const getInitialValues = () => ({
    name: '',
  });

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required('Name is required'),
    });

  useImperativeHandle(
    ref,
    () => ({
      submitForm: () => {
        formikRef.current.submitForm();
      },
    }),
    [],
  );
  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={getValidationSchema()}
      onSubmit={handleSave}
      innerRef={formikRef}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit, touched, errors } = formikProps;
        return (
          <form>
            <Input
              placeholder={'Quick Filter Name'}
              name={'name'}
              value={values.name}
              autoFocus={true}
              errorMsg={touched.name && errors.name && errors.name}
              onChange={(e) => setFieldValue('name', e.target.value)}
              onPressEnter={handleSubmit}
            />
          </form>
        );
      }}
    </Formik>
  );
});

export default AddQuickFilterBody;
