import React, { useState } from 'react';
import cx from 'clsx';
import { Card } from 'react-bootstrap';
import Timeline from '../../common/timeline/TimeLine';
import { isEmptyArray, isFieldRestricted } from '../../utils';
import styles from './RecentActivity.module.scss';
import DisplayDateTime from '../../utils/time/display_time';
import { Flex } from 'antd';
import { Button } from '../../common';

const RecentActivity = ({ name = '', data = [] }) => {
  const RowComponent = ({ data = {} }) => {
    const [readMore, setReadMore] = useState(false);

    return (
      <Flex vertical className="mb-4">
        <Flex className="item-separator-dot subtitle-2">
          <div>{data?.trackableType || ''}</div>
          <div>{data?.action || ''}</div>
        </Flex>
        <div className="text-2">{DisplayDateTime(data?.timestamp)}</div>
        {data?.messages?.length && !isFieldRestricted(data?.messages) && (
          <div className="mt-2">
            <div className={cx('text-1', styles.timelineComment)}>
              {readMore
                ? (data?.messages).map((message, index) => {
                    return <span key={index}>{message}</span>;
                  })
                : data?.messages.slice(0, 2).map((message, index) => {
                    return <span key={index}>{message}</span>;
                  })}
            </div>
            {data?.messages?.length > 2 ? (
              <Button text={readMore ? 'show less' : 'show more'} type="link" onClick={() => setReadMore(!readMore)} />
            ) : (
              ''
            )}
          </div>
        )}
      </Flex>
    );
  };

  return (
    <div className="mb-2 scroll-y">
      {!isEmptyArray(data) && (
        <>
          {name?.length ? <Card.Subtitle className={cx('mb-2 ms-1 ', styles.headingText)}>{name}</Card.Subtitle> : null}
          <Timeline timeLineData={data} TimeLineBodyComponent={RowComponent} />
        </>
      )}
    </div>
  );
};

export default RecentActivity;
