import React, { useState } from 'react';
import Button from '../button/Button';
import toast from 'react-hot-toast';

const CopyToClipboard = ({ value = '', errorText = '', btnSize = 'small' }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      toast.error(errorText);
    }
  };
  const copyField = () => {
    setTimeout(() => {
      copyContent();
      setIsCopied(true);
    }, 200);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <Button type={'link'} icon={isCopied ? 'IoCheckmarkCircle' : 'MdContentCopy'} size={btnSize} onClick={copyField} />
  );
};

export default CopyToClipboard;
