import { Flex } from 'antd';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import styles from './FilterChip.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  convertQueryObjToString,
  mapQueryStringToFilterObject,
  removeChildFilter,
  removeFilterOnDemand,
} from '../../../utils/filters-util';

const FilterChip = ({ baseList, searchKey, searchValue, chipTitle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const setSlugAsKey = baseList?.find((l) => l?.key === searchKey)?.setSlugAsKey;

  const onClose = () => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const obj = { ...queryObj?.queryObj };
    removeChildFilter(baseList, searchKey, obj, searchValue, setSlugAsKey);
    removeFilterOnDemand(obj, searchKey);
    navigate({
      pathname: location.pathname,
      search: convertQueryObjToString({ ...obj }),
    });
  };

  return (
    <>
      <Flex align="center" gap={6} className={styles.filterChip} key={chipTitle}>
        <div>{chipTitle}</div>
        <IoClose size={14} color="var(--gray-500)" className="cursorPointer" role="button" onClick={onClose} />
      </Flex>
    </>
  );
};

export default FilterChip;
