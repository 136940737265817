import React, { Fragment, useRef, useState } from 'react';
import { Button, Modal } from '../../../common';
import CreateTicketModalBody from './CreateTicketModalBody';
import { createLeadSupportTicket } from '../../../api/leads-api';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';

const CreateTicketModal = ({ disabled = false, assignees = [], leadId = '', onSuccessCreate = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = async (values) => {
    if (!values?.assignee?.id || !leadId) return;
    try {
      setLoading(true);
      const payload = {
        ticket: {
          assignee_id: values?.assignee?.id || '',
          description: values?.description || '',
        },
      };

      const res = await createLeadSupportTicket(payload, leadId);
      if (res && res?.status) {
        onSuccessCreate();
        toast.success('Ticket Created Successfully');
        setModalOpen(false);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        onClick={() => setModalOpen(true)}
        icon={'BsPlus'}
        text="Create Ticket"
        size={'large'}
        disabled={disabled}
      />

      <Modal
        title="Create Support Ticket"
        open={modalOpen}
        ModalBody={<CreateTicketModalBody assignees={assignees} handleSubmit={handleSubmit} ref={formRef} />}
        handleCancel={() => setModalOpen(false)}
        handleOk={() => {
          formRef?.current?.submitForm();
        }}
        okButtonProps={{ loading }}
        okText="Create"
        showActions={true}
        destroyOnClose={true}
        width={700}
      />
    </Fragment>
  );
};

export default CreateTicketModal;
