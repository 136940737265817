import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ items = [] }) => {
  function itemRender(currentRoute, params, items, paths) {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;

    return isLast ? (
      <span className="subtitle-2">{currentRoute.title}</span>
    ) : (
      <Link className="link-text" to={`/${paths.join('/')}`}>
        {currentRoute.title}
      </Link>
    );
  }

  return <AntdBreadcrumb className={styles.customBreadCrumb} separator=">" itemRender={itemRender} items={items} />;
};

export default Breadcrumb;
