import React from 'react';
import DetailCard from '../../detail-card/DetailCard';
import { Flex } from 'antd';
import StatusButton from '../../../utils/status/status_button';
import DisplayDateTime from '../../../utils/time/display_time';
import LeadOrderCharges from './LeadOrderCharges';
import { getCurrencyString } from '../../../utils';

const LeadOrders = ({ ordersData = [] }) => {
  const getOrderDetailData = (orderItem = {}) => {
    const orderRowDta = [
      {
        label: 'Product',
        value: orderItem?.product?.name ?? '-',
      },
      {
        label: 'Order Date',
        value: DisplayDateTime(orderItem?.order_date) ?? '-',
      },
      {
        label: 'Total',
        value: getCurrencyString(orderItem?.total) ?? '-',
      },
      {
        label: 'Refund',
        value: getCurrencyString(orderItem?.refund) ?? '-',
      },
    ];
    const orderSubscriptionData = orderItem?.subscription
      ? [
          {
            label: 'Initial Price',
            value: getCurrencyString(orderItem.subscription?.initial_price) ?? '-',
          },
          {
            label: 'Recurring Price',
            value: getCurrencyString(orderItem.subscription?.recurring_price) ?? '-',
          },
          {
            label: 'Start Date',
            value: DisplayDateTime(orderItem.subscription?.start_date) ?? '-',
          },
          {
            label: 'Next Rebilling Date',
            value: DisplayDateTime(orderItem.subscription?.next_rebilling_date) ?? '-',
          },
        ]
      : [];

    return [...orderRowDta, ...orderSubscriptionData].filter(Boolean);
  };

  const getTitleExtraItems = (orderItem = {}) => {
    return (
      <Flex gap={10} style={{ overflowX: 'auto' }}>
        <StatusButton status={orderItem?.status} />
        {orderItem?.subscription && (
          <StatusButton status={{ name: 'Subscription', bg_color: '#FEF2F2', color: '#DC2626' }} />
        )}
      </Flex>
    );
  };

  const getCardTitle = (orderItem = {}) => {
    return `Order # ${orderItem?.samcart_order_id}`;
  };
  return (
    <Flex vertical gap={15}>
      {ordersData?.length ? (
        ordersData?.map((orderItem) => (
          <DetailCard
            key={orderItem?.id}
            textToCopy={orderItem?.samcart_order_id}
            cardRowData={getOrderDetailData(orderItem)}
            cardTitle={getCardTitle(orderItem)}
            CardTitleExtraContent={getTitleExtraItems(orderItem)}
            CardActionContent={
              <LeadOrderCharges orderCharges={orderItem?.charges} orderChargesDescription={getCardTitle(orderItem)} />
            }
          />
        ))
      ) : (
        <div>{'No Data Found'}</div>
      )}
    </Flex>
  );
};

export default LeadOrders;
