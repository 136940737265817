import React from 'react';
import DetailCardVertical from '../../detail-card/DetailCardVertical';

const LeadDetailCard = ({ detailData = {} }) => {
  const title = `${detailData?.first_name} ${detailData?.last_name}`;

  const getDetailsData = () => {
    return [
      {
        label: 'Email',
        value: detailData?.email || '-',
        isPrimaryText: true,
      },
      {
        label: 'Phone',
        value: detailData?.phone_number || '-',
        isPrimaryText: true,
      },
      {
        label: 'Gender',
        value: detailData?.gender_label || '-',
      },
      {
        label: 'Date of Birth',
        value: detailData?.date_of_birth || '-',
      },
      {
        label: 'State',
        value: detailData?.date_of_birth || '-',
      },
      {
        label: 'Secondary Email',
        value: detailData?.secondary_email || '-',
        isPrimaryText: true,
      },
      {
        label: 'Secondary Phone',
        value: detailData?.secondary_phone_number || '-',
        isPrimaryText: true,
      },
    ];
  };

  return <DetailCardVertical cardTitle={title} cardData={getDetailsData()} />;
};

export default LeadDetailCard;
